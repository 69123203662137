import axios from 'axios';
import getAuthorizationToken from '@/utils/getAuthorizationToken';

let fetcher;
let retryCounter = 0;

export default (config) => {
  const {store, path, method, payload} = config;
  if (!fetcher) {
    fetcher = axios.create();

    let accessToken;
    fetcher.interceptors.request.use(
      async (request) => {
        if (!accessToken) {
          accessToken = await getAuthorizationToken();
        }

        request.headers.Authorization = `Bearer ${accessToken}`;

        return request;
      },
      (err) => Promise.reject(err)
    );

    fetcher.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.log(error);
        const originalRequest = error.config;
        if (error.response?.status === 401) {
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            accessToken = await getAuthorizationToken();
            return fetcher(originalRequest);
          }
        }
        if (error.response?.status === 403) {
          store.dispatch('setAuthenticationError', true, {root: true});
        }
        if (
          (error.error !== 'login_required' && !error.response) ||
          error.response?.status >= 500
        ) {
          if (retryCounter < 3) {
            retryCounter += 1;
            return fetcher(originalRequest);
          } else {
            retryCounter = 0;
            store.dispatch('setModalError', '', {root: true});
          }
        }
        return Promise.reject(error);
      }
    );
  }
  return new Promise((resolve, reject) => {
    fetcher[method](path, payload)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

<template>
  <hs-popup
    aria-labelledby="still-here-title"
    confirm-button-text="Stay Signed In"
    decline-button-text="Sign me out"
    @hide="emitHide"
    @confirm="emitHide"
    @decline="logout"
  >
    <h1 class="hs-popup__title still-here__title" id="still-here-title">{{ title }}</h1>
  </hs-popup>
</template>

<script>
import {scBase} from '@/constants/web-redirects';
export default {
  props: {
    logoutTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      secondsLeft: 0,
      timer: null,
    };
  },
  computed: {
    title() {
      const minutes = Math.floor(this.secondsLeft / 60);
      const seconds = this.secondsLeft - minutes * 60;
      return `You will be logged out in ${minutes ? minutes + 'm ' : ''}${seconds + 's'}`;
    },
    noSeconds() {
      return !this.secondsLeft || this.secondsLeft < 0;
    },
  },
  methods: {
    clearTimer() {
      if (this.timer) clearInterval(this.timer);
      this.timer = null;
    },
    emitHide() {
      this.clearTimer();
      this.$emit('hide');
    },
    logout() {
      window.location = `${scBase}/sc/shop/Accounts/Signout`;
    },
    recalculateSecondsLeft() {
      this.secondsLeft = Math.floor((this.logoutTime - new Date()) / 1000);
      if (this.noSeconds) {
        this.clearTimer();
        this.logout();
      }
    },
  },
  mounted() {
    this.recalculateSecondsLeft();
    if (!this.noSeconds) this.timer = setInterval(this.recalculateSecondsLeft, 1000);
  },
  destroyed() {
    this.clearTimer();
  },
};
</script>

<style lang="scss">
.still-here__title {
  text-align: center;
  &::before {
    display: inline-block;
    margin-right: 5px;
    padding: 0 8px;
    color: $white;
    font-weight: 900;
    background-color: $primaryRed;
    border-radius: 50%;
    content: '!';
  }
}
</style>